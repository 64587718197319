<template>
  <section>
    <!-- title text and switch button -->
    <div class="text-center">
      <b-row class="pricing-card">
        <b-col v-if="data" sm="12" md="10" offset-lg="2" lg="6" class="mx-auto">
          <b-alert v-if="data.status == true" variant="success" show>
            <h4 class="alert-heading">{{$t("Pages.Shop.Title.Successful")}}</h4>
            <div class="alert-body">
              <br />
              <span>{{ data.message }}</span>
              <br /><br />
              <span
                >{{ $t("Pages.Shop.Title.trans_num : {transaction_id}",{transaction_id:data.transactionId}) }}</span
              >
              <br /><br />
              <span>{{ $t("Pages.Shop.Title.trans_info") }} </span>
              <br />
              <span class="word-break" v-html="data.info"></span>
              <br /><br />
            </div>
          </b-alert>
          <b-alert v-else variant="danger" show>
            <h4 class="alert-heading">{{ $t("Pages.Shop.Title.Unsuccessful") }}</h4>
            <div class="alert-body">
              <br />
              <span>{{ data.message }}</span>
              <br /><br />
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </div>
    <!--/ title text and switch button -->
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BAlert } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
/* eslint-disable global-require */
export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BCard,
    AppCollapseItem,
    AppCollapse,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.makeToast(
      this.$t("Base.Alert.please_wait"),
      this.$t("Pages.Shop.Alert.recieving_info"),
      "success"
    );
    const payload = {
      Authority: this.$route.query.Authority,
      Status: this.$route.query.Status,
    };
    this.$http
      .post(`/api/shop/pay/back`, payload)
      .then((response) => {
        if (response.data.status == true) {
          this.data = {
            status: true,
            message: this.$t("Pages.Shop.Alert.payment_success"),
            transactionId: response.data.message,
            info: response.data.info,
          };
        } else {
          this.data = {
            status: false,
            message: response.data.message,
            transactionId: "",
            info: "",
          };
        }
      })
      .catch(() => {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Pages.Shop.Alert.transfer_portal"),
          "danger"
        );
      });
  },
  methods: {

  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

.word-break {
  word-break: break-all;
}
</style>
